:root {
  --primary-light: #39c9dc;
  --secondry-light: #3ea5fa;
  --text-primary: #000000;
  --text-secondary: #000000;

  --background-dark: #1c1c1c;
  --foreground-dark: #272727;
  --divider-dark: #333333;
  --text-dark: #ffffff;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Franie", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
