.homepage-header-text {
  background: linear-gradient(
    90deg,
    var(--secondry-light) 0%,
    var(--primary-light) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: -2.16px;
}

/* // ----------------------------
//  Copyright (C) 2024 Everlign LLC
//  All Rights Reserved.

//  This file is part of Everlign's Generative AI Platform.

//  Proprietary and confidential. Unauthorized copying of this file,
//  via any medium, is strictly prohibited.

//  File Name: style.css
//  Author: Everlign Development Team
// ---------------------------- */
