/* General styling for paragraph */
.markdown-content code {
  max-width: 1200px;
  width: 80%;
  word-wrap: break-word;
  white-space: pre-wrap; /* Ensures that whitespace and newlines are preserved */
}

.markdown-content p {
  margin-bottom: 16px; /* Consistent spacing between paragraphs */
}

.markdown-content h3 {
  font-size: medium;
  font-weight: 600;
}

/* Styling for unordered lists */
.markdown-content ul {
  list-style-type: disc;
  margin-bottom: 16px; /* Consistent bottom margin with paragraphs */
  padding-left: 20px; /* Proper indentation of list items */
}

.markdown-content li {
  margin-bottom: 10px; /* Consistent bottom margin with paragraphs */
}

/* Enhanced link styling */
.markdown-content a {
  color: #3fa0fe; /* Eye-catching blue for links */
  font-style: italic; /* Italicized to differentiate from other text */
  text-decoration: none; /* Removes underline by default */
  transition: color 0.3s; /* Smooth color transition on hover */
}
.markdown-content em {
  font-style: italic; /* Ensures it remains italicized */
  font-weight: 600;
}

.markdown-content a:hover,
.markdown-content a:focus {
  color: #297ccf; /* Darker blue on hover/focus for better interaction */
  text-decoration: underline; /* Adds underline on hover/focus for clarity */
}

/* Responsive and styled tables */
.markdown-content table {
  width: 80%; /* Ensures table width is responsive to container size */
  max-width: 100%; /* Prevents table from overflowing its container */
  background: #f1f3f6; /* Subtle background color for table */
  border-collapse: collapse; /* Collapses border for a cleaner look */
  border-radius: 8px; /* Rounded corners for a modern look */
  overflow: hidden; /* Hides any overflow caused by border radius */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  margin-bottom: 30px; /* Consistent spacing below tables */
  margin-top: 30px; /* Consistent spacing below tables */
}

/* Styling for table cells */
.markdown-content th,
.markdown-content td {
  padding: 12px 15px; /* Slightly reduced vertical padding */
  border-bottom: 1px solid #ccc; /* Slightly darker line to separate rows */
  text-align: left; /* Aligns text to the left in cells */
  background-color: #f9fafc;
}

.markdown-content th {
  background-color: white; /* Slightly darker background for headers */
  color: #333; /* Darker text color for better readability */
  font-size: 14px; /* Larger font size for headers */
}

.markdown-content td {
  color: #666; /* Lighter text color for data cells */
  font-size: 14px; /* Standard font size for data */
}

.dark .markdown-content th {
  background-color: #1c1c1c; /* Slightly darker background for headers */
  color: #fff;
  border-bottom: 1px solid #3f3f3f;
  /* Lighter text color for better readability */
}

.dark .markdown-content td {
  background-color: #2d2d2d;
  color: #fff;
  border-bottom: 1px solid #3f3f3f;
}
